import React from "react";

import {MainLayout} from "../components/layout"
import {SectionHeader} from "./index";

import shared from "../styles/shared.module.styl";
import { Helmety } from "../components/Head";
import { InfoSection } from '../components/info';

export default class PuntosDeVenta extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/puntos_de_venta/"
          title="Recepción de manuscritos - Dharma Books"
          content="Libro objeto a precio discreto."
          imgURL="https://www.dharmabooks.com.mx/static_resources/logos/griso_sl.png"
        />
        <div className={shared.container}>
          <InfoSection
            header="Recepción de Manuscritos"
            content={(<React.Fragment>
              <div className={shared.textcontainer} lang="es">
                <p>Por el momento, no estamos recibiendo manuscritos; nos han sido entregadas más propuestas de las que podemos atender.</p>

                <p>Anunciaremos por este medio la apertura de nuestro correo de dictamen para que nos manden sus propuestas.</p>

                <p>Gracias por su comprensión.</p>
              {/*<p>En Dharma Books + Publishing estamos interesados en leer y publicar manuscritos de poesía, ficción y no-ficción (ya sea crónica o ensayo). Para que podamos acercarnos a su trabajo es indispensable que nos hagan llegar una copia del manuscrito en Word y PDF únicamente al correo*/}
              {/*  <a style={{marginLeft: '5px', marginRight: '5px'}} href="mailto:dictamen@dharmabooks.com.mx">dictamen@dharmabooks.com.mx</a>*/}
              {/*  (no consideraremos trabajos que lleguen por otro medio o a otro correo).</p>*/}
              {/*<p>*/}
              {/*  Las especificaciones de entrega son las siguientes:*/}
              {/*</p>*/}
              {/*<ul>*/}
              {/*  <li>Letra Arial o Times New Roman a 12 pts, con interlineado doble</li>*/}
              {/*  <li>Archivo editable en Word y para lectura en PDF (no aceptamos carpetas para descargar en Dropbox, Google Drive)</li>*/}
              {/*  <li>En la portada del manuscrito deberán incluir título de la obra, nombre del autor o autora y dirección de correo personal.</li>*/}
              {/*  <li>Solo se aceptarán manuscritos vía correo electrónico.</li>*/}
              {/*  <li>Debido a la gran cantidad de manuscritos que recibimos, tardamos de 6 a 12 meses en dar respuesta. No acusamos de recibido. Si el manuscrito es de interés para la editorial, les contactaremos a través del correo proporcionado; de lo contrario, si pasados los 12 meses la editorial no establece contacto con el autor, la respuesta del dictamen se deberá tomar como negativa.</li>*/}
              {/*</ul>*/}
            </div>
            </React.Fragment>)} />
        </div>
      </MainLayout>
    )
  }
}

